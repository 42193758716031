import { ConfigProvider } from "antd";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";

import appStore from "store/app";

import Footer from "components/Footer";
import Header from "components/Header/Header";

import ScrollToTop from "ScrollToTop";
import AboutCompany from "components/AboutCompany";
import Advantages from "components/Advantages";
import CalculatorSection from "components/CalculatorSection";
import CallbackModal from "components/CallbackModal";
import NoLoanSection from "components/NoLoanSection";
import PromoInfo from "components/PromoInfo";
import { loanStepsArray } from "constants/onlineLoan";
import BaseSnackbar from "form-submodule/atoms/BaseSnackbar";
import ModalContainer from "form-submodule/atoms/ModalContainer";
import OnlineLoanRegistration from "form-submodule/organisms/OnlineLoanRegistration";
import {
	backReq,
	getGeolocation,
	getGeolocationPermission,
	sendGeolocation,
} from "helpers";
import SnackbarProvider from "react-simple-snackbar";
import {
	CITYADS,
	CPA,
	LEADS,
	STANDARD_OPTION,
	UTM,
	setCookie,
} from "utils/cookie";

const App = observer(() => {
	const [isLoansBlocked, setIsLoansBlocked] = useState(false);

	useEffect(() => {
		const transactionId = new URLSearchParams(window.location.search).get(
			"transaction_id",
		);

		if (transactionId) {
			localStorage.setItem("transactionId", transactionId);
		}

		async function fetchIsBlocked() {
			pwaInstall();

			const response = await backReq("check_block");

			if (response.errors) {
				setIsLoansBlocked(true);
				return false;
			}

			const { block = false } = response?.data;
			setIsLoansBlocked(block);
			return block;
		}

		fetchIsBlocked().then((isBlocked) => {
			if (isBlocked) return;
			appStore.getLoanRemoteFieldValues();
			const loanId = localStorage.getItem("loanId");
			if (loanId) {
				appStore
					.refreshLoanDetail(loanId)
					.then(changeCurrentStep)
					.catch((e) => console.error(e));
			}
		});
		getUtm();

		// send geolocation
		if (!localStorage.getItem("geolocationId")) {
			getGeolocationPermission().then((perm) => {
				if (perm.state === "granted" || perm.state === "prompt") {
					getGeolocation().then((pos) => {
						sendGeolocation({
							longitude: pos.coords.longitude,
							latitude: pos.coords.latitude,
						});
					});
				}
			});
		}
	}, []);

	const pwaInstall = () => {
		window.addEventListener("beforeinstallprompt", (e) => {
			e.preventDefault();
			const beforeInstallPromptEvent = e;
			if (!window.location.search) return;
			const params = new URL(window.location.href);
			const downloadPwa = params.searchParams.get("download_pwa");
			if (downloadPwa) {
				beforeInstallPromptEvent.prompt();
			} else {
				setTimeout(() => beforeInstallPromptEvent.prompt(), 60000);
			}
		});
	};

	const getUtm = () => {
		const queryString = window?.location?.search;

		const params = new URLSearchParams(queryString);
		const isCityADS = params.get("utm_source") === CITYADS;
		const isLeads = params.get("source") === LEADS;
		if (isCityADS || isLeads) {
			sessionStorage.setItem(CPA, window?.location);
			setCookie(UTM, window?.location, STANDARD_OPTION);
		} else if (queryString) {
			localStorage.setItem(UTM, window?.location);
		}
	};

	const changeCurrentStep = () => {
		const currentStep = appStore.loanDetail.currentStep;
		if (!currentStep) return;
		let stepIndex = loanStepsArray.findIndex((item) => item === currentStep);
		if (currentStep === "summary") stepIndex = 4;
		if (!(stepIndex > -1)) return;
		appStore.setCurrentStep(stepIndex, true);
	};

	const { isLoanModalVisible } = appStore;

	const Main = ({ referCode }) => (
		<SnackbarProvider>
			<div>
				<Header />
				<div id={"box"}>
					{isLoansBlocked ? (
						<NoLoanSection />
					) : (
						<>
							<CalculatorSection
								referCode={/^\d{1,6}$/.test(referCode) ? referCode : ""}
							/>
							<Advantages />
							<PromoInfo />
							<AboutCompany />
						</>
					)}
				</div>
				<Footer />
			</div>
			<ModalContainer
				showModal={isLoanModalVisible}
				closeModal={() => appStore.setIsLoanModalVisible(false)}
				size="large"
				closeOnBlur={false}
				isTitle={false}
			>
				<OnlineLoanRegistration gap={10} />
			</ModalContainer>
			<BaseSnackbar />
			<CallbackModal />
		</SnackbarProvider>
	);

	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: "#4caf50",
				},
				components: {
					Button: {
						fontWeight: 600,
					},
					Slider: {
						dotActiveBorderColor: "#4CAF50",
						dotBorderColor: "#E9E9E9",
						handleActiveColor: "#4CAF50",
						handleColor: "#4CAF50",
						handleSize: 24,
						handleSizeHover: 26,

						trackBg: "#4CAF50",
						trackHoverBg: "#4CAF50",

						colorPrimary: "#4CAF50",
						colorPrimaryBorder: "#4CAF50",
						colorPrimaryBorderHover: "#4CAF50",

						colorText: "#608185",
						colorTextDescription: "#608185",
						fontFamily: "Muller",
						fontSize: 16,
					},
					Switch: {
						colorPrimary: "#52C41A",
						colorPrimaryBorder: "#52C41A",
						colorPrimaryHover: "#52C41A",
					},
				},
			}}
		>
			<Router>
				<ScrollToTop />
				<Route
					path="/:referCode"
					render={(props) => <Main referCode={props.match.params.referCode} />}
				/>
				<Route exact path="/" render={() => <Main />} />
			</Router>
		</ConfigProvider>
	);
});

export default App;
