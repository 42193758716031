import { loanStepsArray } from "constants/onlineLoan";
import BaseMessage from "form-submodule/atoms/BaseMessage";
import ErrorContainer from "form-submodule/molecules/ErrorContainer";
import StepChangeButtons from "form-submodule/molecules/StepChangeButtons";
import Card from "form-submodule/steps/Card";
import { nextStepHandler } from "form-submodule/steps/helpers";
import { backReq, backReqV2 } from "helpers";
import useErrorContainer from "hooks/useErrorContainer";
import useRefreshOnlineLoanDetail from "hooks/useRefreshOnlineLoan";
import React, { useEffect, useState, useRef } from "react";
import { forwardRef, useImperativeHandle } from "react";
import appStore from "store/app";
import elementsStore from "store/elementsStore";
import draftFromState from "utils/draftFromState";
import { trimmedPhone, trimmedPhoneWithPlus } from "utils/formattedPhone";
import onSuggestion from "utils/onSuggestion";
import { validateAddress } from "utils/validators";
import ContactPersonForm from "./ContactPersonForm";
import IncomeForm from "./IncomeForm";
import s from "./OnlineLoanIncome.module.scss";

class ContactPersonModel {
	contact = "";
	fio = "";
	phone = "";
	address = undefined;
}

const RegistrationIncome = (props, ref) => {
	const [isIncomeDataError, setIsIncomeDataError, incomeDataContainerRef] =
		useErrorContainer();
	const [isContactDataError, setIsContactDataError, contactDataContainerRef] =
		useErrorContainer();

	const [incomeForm, setIncomeForm] = useState(null);
	const [contactForms, setContactForms] = useState(null);
	const [cardForm, setCardForm] = useState(null);

	const cardRef = useRef();

	useRefreshOnlineLoanDetail(insertData);

	async function insertData() {
		const loan = appStore?.loanDetail || {};

		const draft = draftFromState({
			...loan,
			contacts: "",
		});

		setCardForm({
			orderEmail:
				draft.orderEmail?.value || draft.orderEmail || loan.orderEmail,
			promoAgreement: draft.promoAgreement
				? JSON.parse(draft.promoAgreement)
				: loan.promoAgreement || true,
			noThirdParties: draft.noThirdParties
				? JSON.parse(draft.noThirdParties)
				: loan.noThirdParties,
		});

		setIncomeForm({
			...loan,
			employmentStatus: draft.employmentStatus || loan.employmentStatus,
			fieldOfActivity: draft.fieldOfActivity || loan.fieldOfActivity,
			employmentPosition: draft.employmentPosition || loan.employmentPosition,
			company: draft.company?.value || draft.company || loan.company,
			income: draft.income || loan.income,
			creditMonthSum: draft.creditMonthSum || loan.creditMonthSum || 0,
			companyAddress:
				draft.companyAddress?.value ||
				draft.companyAddress ||
				loan.companyAddress ||
				"",
			publicPerson: draft.publicPerson
				? JSON.parse(draft.publicPerson)
				: loan.publicPerson || false,
			// isFnsAgreement: draft.isFnsAgreement
			// 	? JSON.parse(draft.isFnsAgreement)
			// 	: loan.isFnsAgreement || true,
		});

		const contacts = [];

		const c = draft.contacts || loan.contacts;
		for (let i = 0; i < c.length; i++) {
			const item = c[i];

			let a;
			if (item?.addressObj?.city) {
				a = {
					address: item.address,
					addressObj: item.addressObj,
				};
			} else {
				const sugs = await onSuggestion(item.address);
				if (sugs?.[0]) {
					a = {
						address: sugs[0].address?.value || "",
						addressObj: sugs[0].address?.data || {},
					};
				}
			}

			contacts.push({
				...item,
				...a,
			});
		}

		setContactForms(
			contacts.length ? [...contacts] : [new ContactPersonModel()],
		);
	}

	useEffect(() => {
		appStore.getLoanRemoteFieldValues();
	}, []);

	const addContactForms = () => {
		const newContactForms = contactForms.slice();
		newContactForms.push(new ContactPersonModel());
		setContactForms(newContactForms);
	};

	const removeContactForms = () => {
		const newContactForms = [...contactForms];
		newContactForms.pop();
		setContactForms(newContactForms);
		sessionStorage.setItem("contacts", JSON.stringify(newContactForms));
	};

	const updateContactForms = (index, el) => {
		const newForms = [...contactForms];
		newForms[index] = el;
		setContactForms(newForms);
		sessionStorage.removeItem("contacts");
		sessionStorage.setItem("contacts", JSON.stringify(newForms));
	};

	async function validate() {
		console.log(cardForm);

		if (!(await cardRef.current.validate())) {
			return false;
		}

		const {
			employmentStatus,
			company,
			companyAddress,
			fieldOfActivity,
			income,
			creditMonthSum,
			// isFnsAgreement,
		} = incomeForm;

		console.log({
			employmentStatus,
			company,
			companyAddress,
			fieldOfActivity,
			income,
			creditMonthSum,
			// isFnsAgreement,
		});

		if (
			!(
				employmentStatus &&
				(company?.value || company?.trim()) &&
				(companyAddress?.value || companyAddress?.trim()) &&
				fieldOfActivity &&
				income
			)
		) {
			setIsIncomeDataError(true);
			elementsStore.showSnackbar("Заполните поля отмеченные звездочкой");
			return false;
		}
		if (
			!(await validateAddress(
				incomeForm?.companyAddress?.value || incomeForm?.companyAddress,
				true,
			))
		) {
			setIsIncomeDataError(true);
			elementsStore.showSnackbar("Пожалуйста, укажите полный адрес компании");
			return false;
		}
		if (!_isValidContact()) {
			// текст сообщения идет изнутри метода
			setIsContactDataError(true);
			return false;
		}

		return true;
	}

	const _isValidContact = () => {
		for (const contact of contactForms) {
			if (
				!contact.contact &&
				!contact.fio &&
				trimmedPhone(contact.phone).length !== 11
			) {
				elementsStore.showSnackbar("Укажите дополнительное контактное лицо");
				return false;
			}
			if (!contact.contact) {
				elementsStore.showSnackbar("Укажите кем является контактное лицо");
				return false;
			}
			if (!contact.fio) {
				elementsStore.showSnackbar("Укажите ФИО контактного лица");
				return false;
			}
			if (trimmedPhone(contact.phone).length !== 11) {
				elementsStore.showSnackbar("Введите корректный номер телефона");
				return false;
			}
		}
		return true;
	};

	const sendContacts = async () => {
		const contacts = [];
		for (const contact of contactForms) {
			if (!contact.fio) continue;
			const phone = trimmedPhoneWithPlus(contact.phone);
			contacts.push({
				id: contact?.id || null,
				contact: contact.contact,
				fio: contact.fio,
				phone,
				address: contact.address,
			});
		}
		if (contacts.length) {
			const addContactResponse = await backReq("add_contacts", { contacts });
			if (addContactResponse.errors) {
				return addContactResponse;
			}
			return addContactResponse;
		}
		return { errors: false };
	};

	async function sendData() {
		let response;
		response = await sendContacts();
		if (response?.errors) {
			return response;
		}

		console.log({
			id: appStore.loanDetail.id,
			employmentStatus: incomeForm.employmentStatus,
			fieldOfActivity: incomeForm.fieldOfActivity,
			company: incomeForm.company,
			companyAddress: incomeForm.companyAddress,
			income: incomeForm.income,
			creditMonthSum: incomeForm.creditMonthSum || 0,
			// isFnsAgreement: incomeForm.isFnsAgreement,
			cardNumber: appStore.loanDetail.cardNumber || cardForm.cardNumber,
			orderEmail: cardForm.orderEmail?.value || cardForm.orderEmail,
			promoAgreement: cardForm.promoAgreement,
			noThirdParties: cardForm.noThirdParties,
		});

		response = await backReqV2("personal_data", {
			id: appStore.loanDetail.id,
			employmentStatus: incomeForm.employmentStatus,
			fieldOfActivity: incomeForm.fieldOfActivity,
			employmentPosition: incomeForm.employmentPosition,
			company: incomeForm.company,
			companyAddress: incomeForm.companyAddress,
			income: incomeForm.income,
			creditMonthSum: incomeForm.creditMonthSum || 0,
			// isFnsAgreement: incomeForm.isFnsAgreement,
			cardNumber: appStore.loanDetail.cardNumber || cardForm.cardNumber,
			orderEmail: cardForm.orderEmail?.value || cardForm.orderEmail,
			promoAgreement: cardForm.promoAgreement,
			noThirdParties: cardForm.noThirdParties,
		});
		if (response?.errors) {
			return response;
		}
		return response;
	}

	useImperativeHandle(ref, () => ({
		validate,
		sendData,
		current: loanStepsArray[3],
	}));
	return (
		<div>
			<ErrorContainer
				innerRef={incomeDataContainerRef}
				hasError={isIncomeDataError}
			>
				<div className={s.titleWrapper}>
					<div className={s.title}>Сведение о работе и доходах</div>
					<div className={s.message}>
						<BaseMessage
							type="warning"
							shadow={true}
							centered={false}
							close={false}
						>
							Полностью заполненные поля увеличивают шансы на одобрение заявки
						</BaseMessage>
					</div>
				</div>
				{incomeForm && (
					<IncomeForm incomeForm={incomeForm} setIncomeForm={setIncomeForm} />
				)}
			</ErrorContainer>
			<ErrorContainer
				innerRef={contactDataContainerRef}
				hasError={isContactDataError}
			>
				<div className={s.title}>Укажите дополнительные контактные лица</div>
				<div className={s.wrapper}>
					{contactForms?.map((form, index) => (
						<ContactPersonForm
							// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
							key={index}
							contactForm={form}
							setContactForm={updateContactForms.bind(this, index)}
							required={index === 0}
						/>
					))}
					<div className={s.contactActions}>
						<p
							onClick={addContactForms}
							onKeyUp={addContactForms}
							className={s.mainColor}
						>
							+ Добавить контакт
						</p>
						{contactForms?.length > 1 && (
							<p
								onClick={removeContactForms}
								onKeyDown={removeContactForms}
								className={s.errorColor}
							>
								- Удалить контакт
							</p>
						)}
					</div>
				</div>
			</ErrorContainer>

			<ErrorContainer>
				<div className={s.docs}>
					<b>Условия заключения договора</b>
					<a
						href={
							"/docs/Соглашение_об_электронном_взаимодействии_10_03_2023.pdf"
						}
						target="_blank"
						rel="noopener noreferrer"
					>
						Cоглашение об электронном взаимодействии
					</a>
					<a
						href={"/docs/Регламент_о_порядке_ПЭП.pdf"}
						target="_blank"
						rel="noopener noreferrer"
					>
						Регламент о порядке применения/признания ПЭП
					</a>
					<a
						href={
							"/docs/заявление_заемщика_на_подачу_уведомления_в_ФНП_2_в_1.pdf"
						}
						target="_blank"
						rel="noopener noreferrer"
					>
						Соглашение о постановке/снятии в реестр залогов
					</a>
					<a
						href={
							"/docs/Согласие на предоставление в Бюро кредитных историй.pdf"
						}
						target="_blank"
						rel="noopener noreferrer"
					>
						Согласие на предоставление и получение информации в Бюро кредитных
						историй
					</a>
				</div>
			</ErrorContainer>

			{cardForm && (
				<Card ref={cardRef} cardForm={cardForm} setCardForm={setCardForm} />
			)}

			<StepChangeButtons
				callback={nextStepHandler(validate, sendData, loanStepsArray[3])}
				specialDisabled={incomeForm?.publicPerson || !cardForm?.noThirdParties}
			/>
		</div>
	);
};

export default forwardRef(RegistrationIncome);
