import { loanStepsArray } from "constants/onlineLoan";
import { backReq } from "helpers";
import appStore from "store/app";
import elementsStore from "store/elementsStore";
import { errorsParser } from "utils/errorsParser";

export async function validateStep(step) {
	try {
		return await backReq("validate_step", {
			step,
			id: appStore.loanDetail.id,
		});
	} catch (e) {
		return { errors: e.message };
	}
}

// return errors or false
export async function checkCurrentStep(step, noRefresh = false) {
	let response;
	const id = appStore?.loanDetail?.id;
	if (!id) return { errors: "no id" };
	try {
		if (appStore.loanDetail?.currentStep !== step) {
			// response = await backReq("change_step", {
			//     id,
			//     step
			// })
			// if (response.errors) {
			//     return response
			// }
			if (!noRefresh) {
				await appStore.refreshLoanDetail();
			}
			return response;
		}

		return {};
	} catch (e) {
		return { errors: e.message };
	}
}

export async function moveToNextOnlineLoanStep(step) {
	try {
		const currentStepIndex = loanStepsArray.findIndex((s) => s === step);
		await appStore.setCurrentStep(currentStepIndex, true);

		return {};
	} catch (e) {
		return { errors: e.message };
	}
}

export const nextStepHandler =
	(validationFunction, sendDataFunction, currentStep) => async () => {
		try {
			elementsStore.setIsChangeStepButtonLoading(true);
			if (typeof validationFunction === "function") {
				const isValid = await validationFunction();
				if (!isValid) {
					return;
				}
			}
			const id = appStore?.loanDetail?.id;
			if (!id) {
				return { errors: "no id" };
			}

			if (typeof sendDataFunction === "function") {
				const response = await sendDataFunction();
				if (response?.errors) {
					elementsStore.showSnackbar(errorsParser(response.errors));
					return;
				}
			}
			await appStore.setCurrentStep(appStore.currentStep + 1, true);
		} catch (e) {
			console.warn(e);
		} finally {
			elementsStore.setIsChangeStepButtonLoading(false);
		}
	};
