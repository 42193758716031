import { Input } from "antd";
import React from "react";

import {
	inputCardMask,
	inputEptsMask,
	inputInnMask,
	inputPassportSeries,
	inputRegistrationIndex,
	inputSnilsMask,
	inputUnitCodeMask,
} from "utils/cardMask";
import s from "./BaseInput.module.scss";
import ShowPwdActiveIcon from "./img/show-pwd-active-icon.svg";
import ShowPwdIcon from "./img/show-pwd-icon.svg";

const { TextArea } = Input;

class BaseInput extends React.Component {
	constructor(props) {
		super();
		this.props = props;
		this.input = React.createRef();
		this.initialType = this.props.type;
		this.state = {
			inputField: this.props.defaultValue || "",
			selectedType: null,
			currentDate: null,
			typeForMobileKeyboard: [
				"card",
				"passportSeries",
				"snils",
				"inn",
				"onlyNumbers",
				"unitCode",
				"registrationIndex",
			],
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.type === "number" || this.props.type === "currency") {
			if (prevProps.defaultValue > this.props.max) {
				this.setState({
					inputField: this.props.max,
				});
				this.props.value(this.props.max);
			}
		}
		if (
			this.props.type === "date" &&
			this.props.defaultValue === "null-null-null"
		) {
			this.props.value("");
		}
		if (this.props.defaultValue !== prevProps.defaultValue) {
			if (this.props.type === "passportSeries" && this.props.defaultValue) {
				this.setState({
					inputField: inputPassportSeries(String(this.props.defaultValue)),
				});
				return;
			}
			if (this.props.type === "registrationIndex" && this.props.defaultValue) {
				this.setState({
					inputField: inputRegistrationIndex(String(this.props.defaultValue)),
				});
				return;
			}
			this.setState({
				inputField: this.props.defaultValue,
			});
		}
	}

	componentDidMount() {
		this.props.value(this.state.inputField);
		if (this.props.type === "date") {
			this.setCurrentDate();
		}
		if (
			this.props.type === "date" &&
			this.props.defaultValue === "null-null-null"
		) {
			this.props.value("");
		}
	}

	setType(type) {
		this.setState({
			selectedType: type,
			inputField: this.state.inputField,
		});
	}

	handleChange(value) {
		let formattedValue = this.props.onlyLetters
			? value.replace(/[^a-zA-Zа-яА-Я-\s]+/, "")
			: value;

		switch (this.props.type) {
			case "card":
				formattedValue = value.replace(/\D/gi, "");
				formattedValue = inputCardMask(formattedValue);
				break;

			case "number":
			case "currency":
				formattedValue = value.replace(/[^0-9\.]+/g, "");
				formattedValue = formattedValue ? formattedValue : null;
				if (value.length > this.props.maxlength) return;
				break;

			case "password":
				formattedValue = value.replace(/[а-яА-ЯЁё]+/g, "");
				break;

			case "epts":
				formattedValue = value.replace(/\D/gi, "");
				formattedValue = inputEptsMask(formattedValue);
				break;

			case "snils":
				formattedValue = value.replace(/[^0-9\.]+/g, "");
				formattedValue = inputSnilsMask(formattedValue);
				break;

			case "inn":
				formattedValue = value.replace(/[^0-9\.]+/g, "");
				formattedValue = inputInnMask(formattedValue);
				break;

			case "onlyNumbers":
				formattedValue = value.replace(/[^0-9]+/g, "");
				break;

			case "onlyNumbersWithDot":
				formattedValue = value.replace(/[^0-9\.]+/g, "");
				break;

			case "onlySymbols":
				formattedValue = value.replace(/[\s]+/g, "");
				break;

			case "vin":
				if (value.length > 17) return;

				formattedValue = value.toUpperCase().replace(/[^0-9A-HJ-NPR-Z]/g, ""); // Удаляем недопустимые символы сразу в верхнем регистре
				break;

			case "unitCode":
				formattedValue = value.replace(/[^0-9\.]+/g, "");
				formattedValue = inputUnitCodeMask(formattedValue);
				break;

			case "date": {
				const year = Number.parseFloat(value);
				const minYear = 1950;
				const maxDate = Date.parse(new Date());
				if (year < minYear && String(year).length === 4) {
					formattedValue = "1950-01-01";
				}
				if (Date.parse(value) > maxDate) {
					formattedValue = this.state.currentDate;
				}
				break;
			}
			case "passportSeries":
				formattedValue = value.replace(/[^0-9\.]+/g, "");
				formattedValue = inputPassportSeries(formattedValue);
				formattedValue = formattedValue.trim().replace(/\s/g, "");
				break;

			case "registrationIndex":
				formattedValue = value.replace(/[^0-9\.]+/g, "");
				formattedValue = inputRegistrationIndex(formattedValue);
				formattedValue = formattedValue.trim().replace(/\s/g, "");
				break;

			case "regNum":
				formattedValue = value
					.replace(/[^АВЕКМНОРСТУХавекмнорстух\d]+/g, "")
					.toUpperCase();
				break;

			default:
				break;
		}

		if (this.props.upperCase) {
			formattedValue = formattedValue.toUpperCase();
		}

		this.setState({
			selectedType: this.state.selectedType,
			inputField: formattedValue?.trim(),
		});

		if (this.props.sessionKey) {
			sessionStorage.setItem(this.props.sessionKey, formattedValue);
		}

		this.props.value(formattedValue);
	}

	onBlur(e) {
		// fix for number type - ignore e E + -  on input
		if (this.state.inputField === " " && this.props.type === "number") {
			this.setState({
				inputField: "",
			});
		}

		if (this.props.onBlur) {
			this.props.onBlur(e);
		}
	}

	handleCurrencyChange(event, maskedvalue, floatvalue) {
		this.handleChange(floatvalue);
	}

	setCurrentDate() {
		const today = new Date();
		const dd = String(today.getDate()).padStart(2, "0");
		const mm = String(today.getMonth() + 1).padStart(2, "0");
		const yyyy = today.getFullYear();
		this.setState({ currentDate: `${yyyy}-${mm}-${dd}` });
	}

	onCurrencyBlur(e) {
		const res = e.target.value.split(" ").join("");
		this.onBlur({ target: { value: res } });
	}

	onFocus(e) {
		if (this.initialType === "date") {
			e.preventDefault();
		}
		if (this.props.onFocus) {
			this.props.onFocus(e);
		}
	}

	cardType() {
		if (this.state.typeForMobileKeyboard.includes(this.initialType)) {
			return "tel";
		}

		return this.state.selectedType ? this.state.selectedType : this.initialType;
	}

	render() {
		const wrapperClasses = [s.inputWrapper];
		if (this.state.inputField) wrapperClasses.push(s.activePlaceholder);
		if (this.props.size) wrapperClasses.push(s[this.props.size]);
		if (this.props.disabled) wrapperClasses.push(s.disabledPlaceholder);
		if (this.props.type === "date") wrapperClasses.push(s.activePlaceholder);

		return (
			<div className={wrapperClasses.join(" ")} style={this.props.style}>
				<div
					className={[
						s.placeholder,
						this.props.disabled ? s["placeholder--disabled"] : "",
					].join(" ")}
					// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
					dangerouslySetInnerHTML={{ __html: this.props.placeholder }}
				/>
				{this.initialType === "textarea" ? (
					<TextArea
						className={`${s.input} ${s.textarea}`}
						style={{
							height: this.props.height,
							fontSize: this.props.fontSize,
							fontWeight: this.props.fontWeight,
						}}
						onChange={(e) => this.handleChange(e.target.value)}
						onInput={(e) => this.handleChange(e.target.value)}
						onClick={this.onClick}
						disabled={this.props.disabled}
						autoComplete="off"
						value={this.state.inputField}
						maxLength={this.props.maxlength}
						ref={this.input}
						role="presentation"
						autoSize
					/>
				) : (
					<input
						type={this.cardType()}
						className={s.input}
						style={{
							height: this.props.height,
							fontSize: this.props.fontSize,
							fontWeight: this.props.fontWeight,
						}}
						onChange={(e) => this.handleChange(e.target.value)}
						onInput={(e) => this.handleChange(e.target.value)}
						onClick={this.onClick}
						disabled={this.props.disabled}
						autoComplete="off"
						value={this.state.inputField ? this.state.inputField : ""}
						onBlur={(e) => this.onBlur(e)}
						onFocus={(e) => this.onFocus(e)}
						min={this.props.type === "date" ? "1950-01-01" : "0"}
						max={this.props.type === "date" ? "2050-01-01" : "0"}
						maxLength={this.props.maxlength}
						readOnly={this.props.readOnly}
						// biome-ignore lint/a11y/noAutofocus: <explanation>
						autoFocus={this.props.autofocus}
						ref={this.input}
					/>
				)}

				{this.initialType === "password" &&
				(!this.state.selectedType || this.state.selectedType === "password") ? (
					<img
						className={s.icon}
						src={ShowPwdIcon}
						alt=""
						onClick={() => this.setType("text")}
						onKeyUp={(e) => {
							if (e.key === "Enter" || e.key === " ") {
								this.setType("text");
							}
						}}
					/>
				) : this.initialType !== "password" ? null : (
					<img
						className={s.icon}
						src={ShowPwdActiveIcon}
						alt=""
						onClick={() => this.setType("password")}
						onKeyUp={(e) => {
							if (e.key === "Enter" || e.key === " ") {
								this.setType("password");
							}
						}}
					/>
				)}
			</div>
		);
	}
}

export default BaseInput;
