import { observer } from "mobx-react";
import React, { useEffect, useState, useImperativeHandle } from "react";

import BaseCheckbox from "form-submodule/atoms/BaseCheckbox";
import BaseInput from "form-submodule/atoms/BaseInput";
// import DadataInput from "form-submodule/atoms/DadataInput";
import ModalPortal from "form-submodule/atoms/ModalPortal";
import ErrorContainer from "form-submodule/molecules/ErrorContainer";

import { backReq } from "helpers";
import useErrorContainer from "hooks/useErrorContainer";
import useRefreshOnlineLoanDetail from "hooks/useRefreshOnlineLoan";
import appStore from "store/app";
import elementsStore from "store/elementsStore";
import { errorsParser } from "utils/errorsParser";
import { validateEmail } from "utils/validators";

import s from "./OnlineLoanCard.module.scss";

const RegistrationCard = observer(
	React.forwardRef(({ cardForm, setCardForm }, ref) => {
		const [card, setCard] = useState("");
		const [repeatCard, setRepeatCard] = useState("");
		const [isCardLoaded, setIsCardLoaded] = useState(false);
		const [loading, setLoading] = useState(false);
		const [isCardsValid, setIsCardsValid] = useState(false);
		const [isSpecialModalOpen, setIsSpecialModalOpen] = useState(false);
		const {
			orderEmail = "",
			promoAgreement = true,
			noThirdParties = true,
		} = cardForm;

		const [isCardDataError, setIsCardDataError, cardDataContainerRef] =
			useErrorContainer();

		useRefreshOnlineLoanDetail(setCardData);

		async function setCardData() {
			try {
				if (appStore.loanDetail.cardNumber) {
					const str = appStore.loanDetail.cardNumber;
					setCard(`**** ${str.substring(str.length - 4)}`);
					setRepeatCard(`**** ${str.substring(str.length - 4)}`);
					setIsCardLoaded(true);
					setCardForm({ ...cardForm, cardNumber: str });
				}
			} catch (e) {
				console.warn(e);
			}
		}

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		useEffect(() => {
			setIsCardsValid(
				card.length >= 19 && repeatCard.length >= 19 && card === repeatCard,
			);
		}, [card, repeatCard, isCardLoaded]);

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		useEffect(() => {
			if (!card.includes("*") && isCardsValid) {
				setCardForm({ ...cardForm, cardNumber: card });
			}
			// eslint-disable-next-line
		}, [card, isCardsValid]);

		async function checkStatus() {
			if (card !== repeatCard) {
				elementsStore.showSnackbar("Номера карт не совпадают");
				setIsCardDataError(true);
				return false;
			}
			try {
				setLoading(true);
				// const stepErrorResponse = await checkCurrentStep(loanStepsArray[3])
				// if (stepErrorResponse.errors) {
				//     elementsStore.showSnackbar(errorsParser(stepErrorResponse.errors))
				//     return false
				// }
				const response = await backReq("card", {
					cardNumber: card,
					id: appStore.loanDetail.id,
				});
				if (response.code === 200) {
					setIsCardLoaded(true);
					return true;
				}
				elementsStore.showSnackbar(
					errorsParser(response?.errors) || JSON.stringify(response),
				);
				setIsCardDataError(true);
			} finally {
				setLoading(false);
			}
		}

		// function changeCard() {
		//     setCard("")
		//     setRepeatCard("")
		//     setIsCardLoaded(false)
		// }

		async function validate() {
			if ((!card || !repeatCard) && !isCardLoaded) {
				setIsCardDataError(true);
				elementsStore.showSnackbar("Введите карту");
				return false;
			}

			if (!validateEmail(orderEmail?.value || orderEmail)) {
				setIsCardDataError(true);
				elementsStore.showSnackbar(
					errorsParser("Пожалуйста, укажите корректный Email"),
				);
				return false;
			}

			if (!isCardLoaded && card) {
				return await checkStatus();
				// setIsCardDataError(true)
				// elementsStore.showSnackbar('Подтвердите карту')
				// return false
			}
			return true;
		}

		const handlePromoAgreementChanged = () => {
			sessionStorage.setItem("promoAgreement", !promoAgreement);
			setCardForm({ ...cardForm, promoAgreement: !promoAgreement });
		};

		const handleNoThirdPartiesChanged = () => {
			sessionStorage.setItem("noThirdParties", !noThirdParties);
			setCardForm({ ...cardForm, noThirdParties: !noThirdParties });
			// if (noThirdParties) {
			//     setIsSpecialModalOpen(true)
			// }
		};

		useImperativeHandle(ref, () => ({
			validate,
		}));

		return (
			<div style={{ marginTop: 16 }}>
				<ErrorContainer
					innerRef={cardDataContainerRef}
					hasError={isCardDataError}
				>
					<div className={s.card}>
						<h2 className="title">Банковская карта для получения займа</h2>

						<div className={s.textWrapper}>
							<p>
								В поля ниже внимательно введите номер новой банковской карты
								(обычно это от 16 до 19 цифр на лицевой стороне карты).
							</p>
						</div>

						<div className={s.inputsWrapper}>
							<div>
								<BaseInput
									placeholder="Номер карты *"
									type={"card"}
									defaultValue={card}
									value={(x) => setCard(x)}
									disabled={isCardLoaded || loading}
								/>
							</div>
							<div>
								<BaseInput
									placeholder="Повторите номер карты *"
									type={"card"}
									defaultValue={repeatCard}
									value={(x) => setRepeatCard(x)}
									disabled={isCardLoaded || loading}
								/>
							</div>
							<div className={s.newRow}>
								{/* <DadataInput
									type="email"
									placeholder="Email *"
									defaultValue={orderEmail?.value || orderEmail}
									sessionKey="orderEmail"
									value={(x) =>
										setCardForm({ ...cardForm, orderEmail: x.value })
									}
								/> */}
								<BaseInput
									type="email"
									placeholder="Email *"
									defaultValue={orderEmail}
									sessionKey="orderEmail"
									value={(x) => setCardForm({ ...cardForm, orderEmail: x })}
								/>
								<div className={s.infoText}>
									<span>* На Email придет квитанция об оплате</span>
								</div>
							</div>
						</div>

						<div className={s.row}>
							<BaseCheckbox
								value={promoAgreement}
								onChange={handlePromoAgreementChanged}
							>
								Я даю согласие на получение новостей и информации об акциях
								компании
							</BaseCheckbox>
						</div>
						<div className={s.row}>
							<BaseCheckbox
								value={noThirdParties}
								onChange={handleNoThirdPartiesChanged}
							>
								<span>
									Я подтверждаю, что действую от своего имени, без поручения
									третьих лиц/иных бенефициаров.
									<br />
									Соглашаюсь с условиями документов и подтверждаю их подписание
									простой электронной подписью, вводя код из СМС
								</span>
							</BaseCheckbox>
						</div>
						{/* <div className={s.buttonWrapper}>
                        <BaseButton
                            loading={loading}
                            text={isCardLoaded ? "Подтверждено" : "Подтвердить"}
                            size="slim"
                            onClick={checkStatus}
                            disabled={isCardLoaded || !isCardsValid}
                        />
                        {isCardLoaded &&
                            <p onClick={changeCard} className={s.button}>Изменить</p>
                        }
                    </div> */}
					</div>
				</ErrorContainer>
				<ModalPortal
					showModal={isSpecialModalOpen}
					closeModal={() => setIsSpecialModalOpen(false)}
				>
					<div className={s.modal}>
						<p>
							К сожалению мы не можем оформить Вам онлайн-займ. Приглашаем для
							оформления{" "}
							<a href={"https://vashinvestor.ru/client/"}>
								в один из наших офисов
							</a>
							. В случае возникновения сложностей, звоните нам по телефону{" "}
							<a href={"tel:88007006331"}>
								8&nbsp;800&nbsp;700&nbsp;63&nbsp;31
							</a>
						</p>
					</div>
				</ModalPortal>
				{/* <StepChangeButtons callback={nextStepHandler(validate, null, loanStepsArray[5])}/> */}
			</div>
		);
	}),
);

export default RegistrationCard;
